import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ButtonCoreComponent } from '../button-core/button-core.component';

import '@diekeure/dcr-components/dcr-badge.js';
import '@diekeure/dcr-components/dcr-focus-ring.js';
import '@diekeure/dcr-components/dcr-state-layer.js';

@Component({
  selector: 'campus-outline-button, [outline-button]',
  templateUrl: './outline-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OutlineButtonComponent extends ButtonCoreComponent {
  @HostBinding('class')
  defaultClasses = [
    ...this.coreClasses,
    'outline-button',
    'inline-flex',
    'w-fit',
    'border',
    'disabled:border-opacity-disabled',
    'disabled:border-on-surface',
  ];

  @HostBinding('attr.data-has-leading-icon')
  @Input()
  leadingIcon: string;

  @HostBinding('attr.data-has-trailing-icon')
  @Input()
  trailingIcon: string;
}
