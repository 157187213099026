<div class="[ cluster cluster-space-2xs ]">
  <div class="flex-nowrap">
    <campus-icon *ngIf="leadingIcon" [svgIcon]="leadingIcon" class="icon-current"></campus-icon>

    <span class="[ label-large truncate ]"><ng-content></ng-content></span>

    <campus-icon *ngIf="trailingIcon" [svgIcon]="trailingIcon" class="icon-current"></campus-icon>
  </div>
</div>

<dcr-badge *ngIf="badge" [count]="badge" class="[ absolute -top-3xs -right-3xs ]"></dcr-badge>

<dcr-state-layer class="bg-primary" [for]="element"></dcr-state-layer>
<dcr-focus-ring [for]="element"></dcr-focus-ring>
