import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  inject,
  InjectionToken,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl } from '@angular/forms';
import { ArrayFunctions, ObjectFunctions } from '@campus/utils';
import { BehaviorSubject, defer, fromEvent, merge, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, startWith, switchMap, take, takeUntil } from 'rxjs/operators';
import { InputBooleanAttribute, isTruthyInput } from '../core/empty-attribute';
import { SelectableListController } from '../core/selection/selectable-list.controller';
import { SelectionModel } from '../core/selection/selection.model';
import { MenuSelectionChange } from '../menu';
import { RowInterface, RowSelectionChange, TableRowComponent } from './row/row.component';

export interface TableColumnConfigInterface<T = any> {
  key: keyof T;
  label?: string;
  type?:
    | 'text'
    | 'number'
    | 'date'
    | 'datetime'
    | 'time'
    | 'currency'
    | 'percentage'
    | 'boolean'
    | 'icon'
    | 'image'
    | 'link'
    | 'button'
    | 'chips'
    | 'boolean-sync'
    | 'status';

  href?: string;
  sortable?: boolean;
  sortComparator?: (a: T, b: T) => number;
  filterable?: boolean;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  alwaysVisible?: boolean;
  visible?: boolean;
  clamp?: number;
  filterOptions?: { id: string | number; label: string }[];
  filterApiKey?: string;
  hidden?: boolean;
  alwaysIncluded?: boolean;
}

export interface TableFilterInterface<T = any> {
  key: keyof T;
  value: any;
}

export interface TablePaginationInterface {
  from: number;
  to: number;
  total: number;
}

export interface TableSortInterface<T = any> {
  key: keyof T;
  direction: 'asc' | 'desc' | undefined;
}

export interface TableRowActionInterface<T = any> {
  label: string;
  icon?: string;
  click: (row: T | T[]) => void;
  condition?: (row: T) => boolean;
}

export interface TableGroupActionInterface<T = any> {
  label: string;
  icon?: string;
  click: (group: T | T[], table?: TableComponent) => void;
  condition?: (group: T) => boolean;
}

export interface TableBulkActionInterface<T = any> {
  label: string;
  icon?: string;
  click: (group: T | T[], table?: TableComponent) => void;
  condition?: (rows: RowInterface<T>[]) => boolean;
}

export class TableSelectionChange<T = any> {
  constructor(public selected: T, public added: T, public removed: T) {}
}

export class TableVisibleColumnsChange<T = any> {
  constructor(
    public selected: TableColumnConfigInterface<T>[],
    public added: TableColumnConfigInterface<T>[],
    public removed: TableColumnConfigInterface<T>[]
  ) {}
}

export class TableShowOnlySelectionChange<T = any> {
  constructor(
    public showOnlySelection: boolean,
    public selectedIds: number[],
    public columns: TableColumnConfigInterface[]
  ) {}
}

// Note: this interface also exists in GroupedData.interface.ts (@campus/dal)
export interface GroupedData<T> {
  [key: string]: any;
  open: boolean;
  children: T[];
  allChildren?: T[];
}

export interface TableRowParentInterface {
  multiple: boolean;
  readonly: boolean;
}

export const CDS_TABLE_ROW_PARENT = new InjectionToken<TableRowParentInterface>('cds-table-row-parent');

const SCROLL_Y_THRESHOLD = 48;
const SCROLL_X_THRESHOLD = 12;

@Component({
  selector: 'campus-table-v2',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T = any>
  implements TableRowParentInterface, OnChanges, OnInit, AfterContentInit, AfterViewInit, OnDestroy
{
  @ViewChildren('rowRef', { read: TableRowComponent }) rows: QueryList<RowInterface<T>>;
  @ViewChild('scrollContainer', { read: ElementRef, static: false }) scrollContainer: ElementRef;

  @HostBinding('class')
  defaultClasses = [
    'cds-table-v2',
    'relative',
    'flex',
    'flex-column',
    'min-block-size-full',
    'border',
    'corner-m',
    'overflow-hidden',
  ];

  //#region Inputs
  @Input() loading = false;

  @Input() data: T[] = [];
  @Input() idKey: Extract<keyof T, number> = 'id' as any;
  @Input() columns: TableColumnConfigInterface<T>[] = [];
  @Input() filters: TableFilterInterface<T>[] = [];
  @Input() groupBy: string;

  @Input() locale = 'nl-BE';
  @Input() headline: string;

  @HostBinding('attr.aria-multiselectable')
  @Input()
  multiple = false;

  @Input() showCheckAll = true;

  @Input() localHandling: InputBooleanAttribute = false;

  @Input() selectable: InputBooleanAttribute = false;
  @Input() disableGrowSearchOnFocus: InputBooleanAttribute;
  @Input() disableTooltipOnTruncated: InputBooleanAttribute;
  @Input() disableTooltip = false;
  @Input() showRefresh: InputBooleanAttribute = false;

  @Input() pageSize: number;
  @Input() pagination: TablePaginationInterface;
  @Input() pageSizeOptions: number[] = [10, 25, 50, 100];

  @Input() compareWith: (o1: any, o2: any) => boolean;

  @Input() readonly = false;

  @Input() rowActions: TableRowActionInterface<T>[];
  @Input() groupActions: TableGroupActionInterface<T>[];
  @Input() bulkActions: TableBulkActionInterface<T>[];

  @Input() initialSelection: T[] = [];

  @Input() searchTerm = null;
  @Input() searchTermOnEnter = false;

  @Input() noResultsMessage = 'Geen resultaten gevonden';
  @Input() noDataMessage = 'Geen data gevonden';
  

  //#endregion

  //#region Public Properties
  @HostBinding('attr.data-has-pagination')
  public hasPagination: boolean | undefined;

  public dataSource: T[] | GroupedData<T>[] = [];
  public visibleColumns: SelectionModel<TableColumnConfigInterface<T>> = new SelectionModel<
    TableColumnConfigInterface<T>
  >(true);

  public showFilters = false;
  public filtersForm: FormArray;
  public searchFocus: boolean;
  public columnConfigDict: Record<string, TableColumnConfigInterface<T>> = {};
  public sortBy: TableSortInterface<T> = { key: undefined, direction: undefined };

  public page = 1;
  public totalItems = 0;

  public someVisibleRowsChecked: boolean;
  public allVisibleRowsChecked: boolean;
  public showOnlySelection = false;
  public selectableController: SelectableListController<RowInterface<T>>;

  public hasRowActions = false;
  public hasGroupActions = false;

  public scrolled$: Observable<{ x: boolean; y: boolean }>;
  private _scrolled$ = new BehaviorSubject({ x: false, y: false });

  private searchTermsDebounceTime = 300;
  private searchTerms$: Observable<string>;
  private _searchTerms$ = new Subject<string>();
  private searchTermSubscription: Subscription = new Subscription();
  public searchTermControl = new FormControl('');

  public trackById: (index: number, item: T) => number;

  //#endregion

  //#region Private Properties
  private _ngZone = inject(NgZone);
  private _formBuilder = inject(FormBuilder);
  private _cd = inject(ChangeDetectorRef);
  // private _filterableColumns$: BehaviorSubject<TableColumnConfigInterface<T>[]> = new BehaviorSubject([]);
  // public filterableColumns$ = this._filterableColumns$.asObservable();
  public filterableColumns: TableColumnConfigInterface<T>[] = [];
  public selectableColumns: TableColumnConfigInterface<T>[] = [];

  private _destroyed$ = new Subject<void>();

  private readonly rowsSelectionChange: Observable<RowSelectionChange<T>> = defer(() => {
    const rows = this.rows;

    if (rows) {
      return rows.changes.pipe(
        startWith(rows),
        switchMap(() => merge(...rows.map((row) => row.selectionChange)))
      );
    }

    return this._ngZone.onStable.pipe(
      take(1),
      switchMap(() => this.rowsSelectionChange)
    );
  }) as Observable<RowSelectionChange<T>>;

  private _initialized = false;

  //#endregion

  //#region Outputs
  @Output() columnsChange = new EventEmitter<TableColumnConfigInterface<T>[]>();
  @Output() visibleColumnsChange = new EventEmitter<TableVisibleColumnsChange<T>>();
  @Output() paginationChange = new EventEmitter<TablePaginationInterface>();
  @Output() pageSizeChange = new EventEmitter<number>();
  @Output() filtersChange = new EventEmitter<TableFilterInterface<T>[]>();
  @Output() searchTermChange = new EventEmitter<string>();
  @Output() sortChange = new EventEmitter<TableSortInterface<T>>();
  @Output() selectionChange = new EventEmitter<TableSelectionChange>();
  @Output() refresh = new EventEmitter<void>();
  @Output() dataChange = new EventEmitter<GroupedData<T>[] | T[]>();

  @Output() showOnlySelectionChange = new EventEmitter<TableShowOnlySelectionChange>();
  @Output() rowClick = new EventEmitter<T>();

  //#endregion

  filterOptionCompareWith = (o1: any, o2: any) => {
    const o1Id = o1?.value?.[this.idKey] || o1?.[this.idKey] || o1;
    const o2Id = o2?.value?.[this.idKey] || o2?.[this.idKey] || o2;
    return o1Id === o2Id;
  };
  //#region Lifecycle Hooks

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.localHandling) {
      this.localHandling = isTruthyInput(changes.localHandling.currentValue);
      const shouldDebounce = this.localHandling;
      this.setupSearchTerms(shouldDebounce);
    }
    if (changes.data) {
      this._setData();
    }
    if (changes.columns && changes.columns.currentValue) {
      this._setColumnConfigDict();
      this._setFilterableColumns();
      this._setSelectableColumns();
      if (changes.columns.firstChange || !changes.columns.previousValue) {
        this._initVisibleColumnsSelection();
      } else {
        this._setVisibleColumns();
      }
      this.columnsChange.emit(this.columns);
    }
    if (changes.groupBy) {
      this._setData();
    }
    if (changes.filters && changes.filters.currentValue) {
      this._initFilterForm(this.filters);
      this._setData();
    }
    if (changes.pageSize || changes.pagination) {
      this.hasPagination = !!this.pageSize || !!this.pagination || undefined;
    }
    if (changes.pageSize) {
      if (!changes.pageSize.firstChange) {
        this.page = this._getNewPageFromPageSizeChange(
          this.page,
          changes.pageSize.previousValue,
          changes.pageSize.currentValue
        );
      }
      this._setData();
    }
    if (changes.multiple) {
      this.multiple = isTruthyInput(this.multiple);
    }
    if (changes.selectable || changes.multiple) {
      this.selectable = isTruthyInput(this.selectable) || this.multiple;
    }
    if (changes.readonly) {
      this.readonly = isTruthyInput(this.readonly);
    }
    if (changes.rowActions) {
      this.hasRowActions = !!this.rowActions?.length;
    }
    if (changes.groupActions) {
      this.hasGroupActions = !!this.groupActions?.length;
    }
    if (changes.disableGrowSearchOnFocus) {
      this.disableGrowSearchOnFocus = isTruthyInput(this.disableGrowSearchOnFocus);
    }
    if (changes.disableTooltipOnTruncated) {
      this.disableTooltipOnTruncated = isTruthyInput(this.disableTooltipOnTruncated);
    }
    if (changes.disableTooltip) {
      this.disableTooltip = isTruthyInput(this.disableTooltip);
    }
    if (changes.idKey) {
      this._setTrackById(this.idKey);
    }
  }

  ngOnInit() {
    this.setupSearchTerms(false);

    if (!this.filtersForm) {
      this._initFilterForm();
    }

    this.scrolled$ = this._scrolled$.pipe(takeUntil(this._destroyed$));

    this._setTrackById(this.idKey);
    this._initialized = true;
  }

  ngAfterContentInit(): void {
    this._initSelectableController();
    this.rowsSelectionChange.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
      this._onSelect(event.source, event.isUserInput);
    });
  }

  ngAfterViewInit(): void {
    this.rows.changes.pipe(takeUntil(this._destroyed$)).subscribe(() => {
      if (this.selectableController) {
        this.rows.forEach((row) => {
          const isSelected = this.selectableController.isSelected(row);

          if (isSelected !== row.selected) {
            row.select();
          } else if (!isSelected && row.selected) {
            row.deselect();
          }
        });

        const rowsArray = this.rows.toArray();
        this.allVisibleRowsChecked = rowsArray.every((r) => r.selected);
        this.someVisibleRowsChecked = rowsArray.some((r) => r.selected) && !this.allVisibleRowsChecked;

        this._cd.detectChanges();
      }
    });

    fromEvent(this.scrollContainer?.nativeElement, 'scroll')
      .pipe(
        takeUntil(this._destroyed$),
        map((event: Event) => {
          const { scrollLeft, scrollTop } = event.target as HTMLElement;
          return { y: scrollTop > SCROLL_Y_THRESHOLD, x: scrollLeft > SCROLL_X_THRESHOLD };
        })
      )
      .subscribe((scrolled) => this._scrolled$.next(scrolled));
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
    this.searchTermSubscription.unsubscribe();
  }
  //#endregion

  //#region Public Methods
  onVisibleColumnsChange(event: MenuSelectionChange<TableColumnConfigInterface<T>>): void {
    const added = event.added.map((column) => column.value);
    const removed = event.removed.map((column) => column.value);

    event.added.forEach((column) => this.visibleColumns.select(column.value));
    event.removed.forEach((column) => this.visibleColumns.deselect(column.value));

    const changeEvent = new TableVisibleColumnsChange<T>(this.visibleColumns.selected, added, removed);
    this.visibleColumnsChange.emit(changeEvent);
  }

  onSearchTermEnterKeyUp(event: KeyboardEvent): void {
    if (!this.searchTermOnEnter) return;

    this._searchTerms$.next(this.searchTermControl.value);
  }

  onSearchTermFocusOut(event: FocusEvent): void {
    if (!this.searchTermOnEnter) return;

    this._searchTerms$.next(this.searchTermControl.value);
  }

  onSearchTermClear(): void {
    if (!this.searchTermOnEnter) return;

    this._searchTerms$.next(this.searchTermControl.value);
  }

  onAddFilter(): void {
    const filter = this._formBuilder.group({
      key: [undefined], // FormControl for key
      value: [undefined], // FormControl for value
    });
    this.filtersForm.push(filter);
  }

  onRemoveFilter(index: number): void {
    this.filtersForm.removeAt(index);

    this.filtersChange.emit(this.filtersForm.value);
  }

  onRemoveAllFilters(): void {
    this.filtersForm.clear();

    this.filtersChange.emit(this.filtersForm.value);
  }

  onSortToggle(column: TableColumnConfigInterface<T>): void {
    if (this.sortBy.key !== column.key) {
      this.sortBy.direction = undefined;
    }

    if (this.sortBy.direction === 'asc') {
      this.sortBy.direction = 'desc';
    } else if (this.sortBy.direction === 'desc') {
      this.sortBy.direction = undefined;
    } else {
      this.sortBy.direction = 'asc';
    }

    this.sortBy.key = column.key;
    this._setData();

    this.sortChange.emit(this.sortBy);
  }

  onToggleCollapseGroup(group: GroupedData<T>): void {
    group.open = !group.open;
  }

  onPageSizeChange(event: MenuSelectionChange<number>): void {
    if (event.added.length === 0) return;

    const pageSize = event.added[0].value;
    this.pageSize = pageSize || 25;

    if (!this.localHandling) {
      this.page = 1;
      this.pagination = this._setPagination(this.page, this.totalItems);
    }

    this._setData();

    this.pageSizeChange.emit(this.pageSize);

    if (!this.localHandling) {
      this.paginationChange.emit(this.pagination);
    }
  }

  nextPage(): void {
    if (this.page * this.pageSize >= this.totalItems) return;
    this.page++;

    this._setData();

    this.paginationChange.emit(this.pagination);
  }

  previousPage(): void {
    if (this.page === 1) return;
    this.page--;
    this._setData();

    this.paginationChange.emit(this.pagination);
  }

  firstPage(): void {
    if (this.page === 1) return;
    this.page = 1;
    this._setData();

    this.paginationChange.emit(this.pagination);
  }

  onCheckAll(): void {
    if (!this.selectable) return;

    const rows = this.rows.toArray().filter((row) => !row.disabled);

    if (!this.allVisibleRowsChecked) {
      rows.forEach((row) => row.select(true));
    } else {
      rows.forEach((row) => row.deselect(true));

      if (this.showOnlySelection) {
        if (this.selectableController.selected.length === 0) return this.onToggleShowOnlySelection();

        this._setData();
        this._cd.detectChanges(); // update rows array
        if (this.rows.length === 0) return this.previousPage();
      }
    }
  }

  setShowOnlySelection(showOnlySelection: boolean): void {
    if (this.showOnlySelection === showOnlySelection) return;

    this.onToggleShowOnlySelection();
  }

  onToggleShowOnlySelection() {
    this.showOnlySelection = !this.showOnlySelection;
    this.firstPage();

    const event = new TableShowOnlySelectionChange(
      this.showOnlySelection,
      this.selectableController.selected.map((s) => s.value[this.idKey] as number),
      this.visibleColumns.selected
    );
    this.showOnlySelectionChange.emit(event);

    if (this.localHandling) {
      this._setData();
    }
  }

  onRefresh(event: MouseEvent) {
    this.refresh.emit();
  }

  onRowClick(row: T): void {
    if (this.readonly) return;

    this.rowClick.emit(row);
  }

  onRowActionClick(action: TableRowActionInterface<T>, row: T): void {
    action.click(row);
  }

  onGroupActionClick(action: TableGroupActionInterface<T>, group: T): void {
    action.click(group, this);
  }

  onBulkClick(action: TableBulkActionInterface<T>): void {
    action.click(this.selectableController.selected.map((s) => s.value));
  }

  clearSelection() {
    this.selectableController?.clear();
    this.setShowOnlySelection(false);
  }

  deselectRows(ids: number[]) {
    const selection = this.selectableController.selected.filter((row) => ids.includes(row.value[this.idKey as any]));
    if (selection.length) this.selectableController.deselect(...selection);
    if (this.selectableController.isEmpty()) this.setShowOnlySelection(false);
  }

  getRowActions(row: T): TableRowActionInterface<T>[] {
    return this.rowActions?.filter((action) => !action.condition || action.condition(row));
  }

  getGroupActions(group: T): TableGroupActionInterface<T>[] {
    return this.groupActions?.filter((action) => !action.condition || action.condition(group));
  }

  getBulkActions(): TableBulkActionInterface<T>[] {
    return this.bulkActions?.filter(
      (action) => !action.condition || action.condition(this.selectableController.selected)
    );
  }

  //#endregion

  //#region Private Methods

  private _initVisibleColumnsSelection(): void {
    const visibleColumns = this.columns.filter((column) => !!column.visible || !!column.alwaysVisible);
    this.visibleColumns = new SelectionModel<TableColumnConfigInterface<T>>(true, visibleColumns, false);
  }

  private _setVisibleColumns(): void {
    this.visibleColumns.setSelection(...this.columns.filter((column) => !!column.visible || !!column.alwaysVisible));
  }

  private _setColumnConfigDict(): void {
    this.columnConfigDict = this.columns.reduce((acc, column) => {
      acc[column.key as string] = column;
      return acc;
    }, {} as Record<string, TableColumnConfigInterface<T>>);
  }

  private _setFilterableColumns(): void {
    this.filterableColumns = this.columns.filter(
      (column) => column.filterable === undefined || column.filterable === true
    );
  }

  private _setSelectableColumns(): void {
    this.selectableColumns = this.columns.filter((column) => !column.hidden && !column.alwaysVisible);
  }

  private _setData(): void {
    let data: GroupedData<T>[] | T[] =
      this.showOnlySelection && this.selectableController && this.localHandling
        ? this.selectableController.selected.map((s) => s.value)
        : this.data;

    if (!data) {
      this.dataSource = [];
      return;
    }

    if (!this.showOnlySelection) {
      data = this._filter(data);
    }
    this.totalItems = this.localHandling ? data.length : this.pagination?.total;

    data = this._map(data);
    data = this._sortBy(data, this.sortBy.key, this.sortBy.direction);

    const allData = data;
    data = this._pageData(data, this.page, this.groupBy);

    data = this._groupBy(data, allData);

    this.dataSource = data;
    this._cd.markForCheck();
    this.dataChange.emit(data);
  }

  private _initFilterForm(initialFilters: TableFilterInterface<T>[] = []): void {
    const isNewForm = !this.filtersForm;
    if (isNewForm) {
      this.filtersForm = this._formBuilder.array([]);
    } else {
      this.filtersForm.clear({ emitEvent: false });
    }

    initialFilters.forEach((filter) => {
      const filterValue = Array.isArray(filter.value) ? new FormControl(filter.value) : filter.value;

      this.filtersForm.push(
        this._formBuilder.group({
          key: filter.key,
          value: filterValue,
        }),
        { emitEvent: false }
      );
    });

    this.filterUnfilterableColumns();

    this.filtersForm.markAsPristine();
    this.filtersForm.updateValueAndValidity({ emitEvent: true });

    if (!isNewForm) return;

    this.filtersForm.valueChanges
      .pipe(
        takeUntil(this._destroyed$),
        startWith(this.filtersForm.value),
        distinctUntilChanged((filters1: TableFilterInterface[], filters2: TableFilterInterface[]) => {
          const isNotEmpty = (f: TableFilterInterface) =>
            f.key !== null && (f.value !== null || this.columnConfigDict[f.key as string]?.type === 'boolean');
          filters1 = filters1.filter(isNotEmpty);
          filters2 = filters2.filter(isNotEmpty);
          return (
            filters1.length === filters2.length && filters1.every((f, i) => ObjectFunctions.deepEquals(f, filters2[i]))
          );
        })
      )
      .subscribe((filters: TableFilterInterface[]) => {
        filters.forEach((_filter) => {
          if (this.columnConfigDict[_filter.key as string]?.type === 'boolean' && _filter.value == null) {
            _filter.value = false;
          }
        });

        this.page = 1;
        this.pagination = this._setPagination(this.page, this.totalItems);

        this.filtersChange.emit(filters);

        this._setData();
      });
  }

  private filterUnfilterableColumns(): void {
    this.filtersForm.value.forEach((filter) => {
      if (!this.filterableColumns.some((column) => column.key === filter.key)) {
        this.filtersForm.removeAt(
          this.filtersForm.value.findIndex((f) => f.key === filter.key),
          { emitEvent: false }
        );
      }
    });
  }

  private _filter(data: T[]): T[] {
    if (!this.localHandling) {
      return data;
    }

    const { searchTerm, visibleColumns } = this;

    return data.filter((row) => {
      let result = true;

      if (searchTerm) {
        result = Object.entries(row)
          .filter(([key, _]) => visibleColumns.selected.some((visibleColumn) => visibleColumn.key === key))
          .some(([_, value]) => {
            return value?.toString().toLowerCase().includes(searchTerm.toLowerCase());
          });
      }

      if (result && this.filtersForm && this.filtersForm.length > 0) {
        result = this.filtersForm.controls.every((control) => {
          const key = control.get('key').value;
          const value = control.get('value').value;

          const config = this.columnConfigDict[key];

          if (value == null || value === '') return true;

          if (Array.isArray(value) && value.length > 0) {
            return value.some((option) => row[key]?.includes(option.label));
          } else if (config?.type === 'boolean' || config?.type === 'boolean-sync') {
            return !!value === !!row[key];
          } else if (config?.type === 'text' || config?.type === 'date' || config?.type === 'datetime') {
            return row[key]?.toString().toLowerCase().includes(value.toString().toLowerCase());
          } else if (config?.type === 'number') {
            return row[key] === +value;
          }
          return true;
        });
      }

      return result;
    });
  }

  private getHref(column: TableColumnConfigInterface<T>, row: T): string {
    if (!column.href) return row[column.key] as string;

    const searchString = `({(.*?)})+`;
    const searchStringRegex = new RegExp(searchString, 'g');
    if (!searchStringRegex.test(column.href)) return row[column.key] as string;

    const match = column.href.match(searchStringRegex)[0];
    const key = match.replace(/[{}]/g, '');
    const value = row[key as keyof T] as string;
    const href = column.href.replace(searchStringRegex, value);

    return href;
  }

  private _map(data: T[]): T[] {
    const columnsToMap = this.columns.filter((column) => column.type === 'link');
    if (!columnsToMap.length) return data;

    const hrefMapper = (column, row) => ({ ...row, href: this.getHref(column, row) });

    const mapperByType = {
      link: (column, row) => hrefMapper(column, row),
      default: (column, row) => row,
    };

    const mappers = columnsToMap.map((column) => [column, mapperByType[column.type] || mapperByType['default']]);

    const mapped = data.map((row) =>
      mappers.reduce(
        (acc, [column, mapFn]) => ({
          ...acc,
          ...mapFn(column, row),
        }),
        {}
      )
    );

    return mapped;
  }

  private _groupBy(data: T[], allData: T[]): GroupedData<T>[] | T[] {
    if (!this.localHandling || !this.groupBy) {
      return data;
    }

    const type = this.columnConfigDict[this.groupBy]?.type;

    const grouped = data.reduce((acc, item) => {
      let key: string;
      if (type === 'date' || type === 'datetime') {
        key = new Date(item[this.groupBy] as string).toLocaleDateString(this.locale);
      } else if (type === 'time') {
        key = new Date(item[this.groupBy] as string).toLocaleTimeString(this.locale);
      } else if (type === 'boolean' || type === 'boolean-sync') {
        key = item[this.groupBy] ? 'ja' : 'nee';
      } else {
        key = item[this.groupBy] as string;
      }

      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {} as Record<string, T[]>);

    return Object.entries(grouped).map(([key, value]) => {
      return {
        [this.groupBy]: key,
        open: true,
        children: value,
        allChildren: allData.filter((d) => key === d[this.groupBy]),
      };
    });
  }

  private _getSortComparator(column: TableColumnConfigInterface<T>, direction: 'asc' | 'desc'): (a: T, b: T) => number {
    const directionMultiplier = direction === 'asc' ? 1 : -1;

    if (column.sortComparator) {
      return (a: T, b: T) => column.sortComparator(a, b) * directionMultiplier;
    }

    if (column.type === 'boolean' || column.type === 'boolean-sync') {
      return (a, b) => (a[column.key] ? -1 : 1) * directionMultiplier;
    }

    return (a: any, b: any) => {
      // More verbose due to differeces between chrome and firefox

      // Handle undefined values
      if (a === undefined && b === undefined) return 0;
      if (a === undefined) return 1;
      if (b === undefined) return -1;

      const aValue = a[column.key];
      const bValue = b[column.key];

      // Handle null values
      if (aValue === null && bValue === null) return 0;
      if (aValue === null) return 1;
      if (bValue === null) return -1;

      // Compare values
      if (aValue < bValue) return -1 * directionMultiplier;
      if (aValue > bValue) return 1 * directionMultiplier;
      return 0;
    };
  }

  private _sortBy(data: T[], key: keyof T, direction: 'asc' | 'desc' | undefined): T[] {
    if (!this.localHandling || !key || direction === undefined) {
      return data;
    }

    return data.sort(this._getSortComparator(this.columnConfigDict[key as string], direction));
  }

  private _pageData(data: T[], page: number, groupBy: string): T[] {
    const total = this.totalItems;
    this.pagination = this._setPagination(page, total);

    if (!this.localHandling || !this.pageSize || !data.length) {
      return data;
    }

    if (groupBy && this.columnConfigDict[groupBy]) {
      data = data.sort(
        this._getSortComparator(
          this.columnConfigDict[groupBy],
          this.sortBy.key === this.groupBy ? this.sortBy.direction || 'asc' : 'asc'
        )
      );
    }

    return data.slice(this.pagination.from - 1, this.pagination.to);
  }

  private _setPagination(page: number, total: number): TablePaginationInterface {
    const from = (page - 1) * this.pageSize;
    const to = from + this.pageSize;

    return {
      from: total ? from + 1 : 0,
      to: Math.min(to, total),
      total,
    };
  }

  private _getNewPageFromPageSizeChange(currentPage: number, previousPageSize: number, newPageSize: number): number {
    const currentFrom = (currentPage - 1) * previousPageSize;
    return Math.floor(currentFrom / newPageSize) + 1;
  }

  private _onSelect(row: RowInterface<T>, isUserInput: boolean): void {
    const wasSelected = this.selectableController.isSelected(row);

    if (wasSelected !== row.selected) {
      if (!this.multiple) {
        this.selectableController.clear();
      }
      if (row.selected) this.selectableController.select(row);
      else this.selectableController.deselect(row);
    }
  }

  private _getCompareWith(): (o1: any, o2: any) => boolean {
    const compareWith =
      this.compareWith ||
      ((o1: RowInterface<T>, o2: RowInterface<T>) => {
        return o1.value[this.idKey] === o2.value[this.idKey];
      });

    return compareWith;
  }

  selectByData(rowData: T[]) {
    this.selectableController.setRawSelection(...rowData);
  }

  deselectByData(rowData: T[]) {
    this.selectableController.deselect(...(rowData as any));
  }

  private _initSelectableController(): void {
    const initialSelection: RowInterface<T>[] = ArrayFunctions.wrapIntoArray(this.initialSelection).map(
      (is) => ({ value: is } as RowInterface<T>)
    );

    this.selectableController = new SelectableListController(
      isTruthyInput(this.multiple),
      initialSelection,
      true,
      this._getCompareWith()
    );

    this.selectableController.changed.pipe(takeUntil(this._destroyed$)).subscribe((event) => {
      const asSelectable = (row: RowInterface<T>) => {
        if (this.selectableController.isSelectable(row)) return row;
        return this.rows.find((r) => this.selectableController.compareWith(r, row));
      };
      event.added.map(asSelectable).forEach((row) => row?.select());
      event.removed.map(asSelectable).forEach((row) => row?.deselect());

      const { selected } = event;
      const selection = {
        selected: this.multiple ? selected.map((s) => s.value) : selected[0]?.value,
        added: event.added.map((s) => s.value),
        removed: event.removed.map((s) => s.value),
      };

      this.allVisibleRowsChecked = this.rows.toArray().every((r) => r.selected);
      this.someVisibleRowsChecked = this.rows.toArray().some((r) => r.selected) && !this.allVisibleRowsChecked;

      this.selectionChange.emit(new TableSelectionChange(selection.selected, selection.added, selection.removed));
    });
  }

  private setupSearchTerms(shouldDebounce?: boolean): void {
    this.searchTerms$ = this.searchTermOnEnter
      ? this._searchTerms$
      : shouldDebounce
      ? this.searchTermControl.valueChanges.pipe(debounceTime(this.searchTermsDebounceTime))
      : this.searchTermControl.valueChanges;

    this.searchTermControl.setValue(this.searchTerm, { emitEvent: false });

    this.searchTermSubscription.unsubscribe();
    this.searchTermSubscription = new Subscription();

    this.searchTermSubscription.add(
      this.searchTerms$.subscribe((term) => {
        this.searchTermChange.emit(term);

        this.page = 1;
        if (this.localHandling) {
          this._setData();
        }
      })
    );
  }

  private _setTrackById(idKey: Extract<keyof T, number>) {
    this.trackById = function (_, item) {
      return item[idKey] as number;
    };
  }
  //#endregion
}
